import { Card as CardWrapper } from "../ui/card";
import { getAltTextFromSrc } from "@/lib/utils";

export interface CardProps {
  image: string;
  link: string;
  title: string;
  editor: string;
  date: string;
  key?: string;
}

const BlogCard = ({ image, link, title, editor, date, key }: CardProps) => {
  return (
    <CardWrapper
      key={key}
      className="bg-secondary-100 max-w-[380px] tablet:w-full mobile:w-full tablet:h-[436px] h-[413px] p-0 gap-0 drop-shadow-md rounded-none"
    >
      <div className="h-full">
        <a href={link}>
          <img
            src={image}
            className="w-[380px] tablet:w-full h-full tablet:h-[240px]"
            alt={getAltTextFromSrc(image)}
            decoding="async"
            loading="lazy"
          />
        </a>
      </div>
      <div className="flex flex-col h-full p-6 gap-8 justify-between">
        <a href={link}>
          <p
            className="text-2xl font-jost font-semibold text-primary-black overflow-hidden"
            style={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
            }}
          >
            {title}
          </p>
        </a>
        <p className="text-sm text-secondary-800">{`${editor} - ${date}`}</p>
      </div>
    </CardWrapper>
  );
};

export default BlogCard;
